@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 400;
  src: local('Manrope'), url('../public/fonts/Manrope-Regular.ttf') format('ttf');
}

@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 100;
  src: local('Manrope'), url('../public/fonts/Manrope-Light.ttf') format('ttf');
}
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 300;
  src: local('Manrope'), url('../public/fonts/Manrope-Light.ttf') format('ttf');
}
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 500;
  src: local('Manrope'), url('../public/fonts/Manrope-Medium.ttf') format('ttf');
}
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 600;
  src: local('Manrope'), url('../public/fonts/Manrope-SemiBold.ttf') format('ttf');
}
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 700;
  src: local('Manrope'), url('../public/fonts/Manrope-Bold.ttf') format('ttf');
}



html {
  font-size: 11px;
  /* font-family: Manrope; */
}

body {
  overflow: hidden; /* Hide scrollbars */
  margin: 0;
  padding: 0;
  background-color: #f5fafd;
  font-family: Manrope;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.carousel {
  flex-direction: column;
  flex-grow: 1;
}

.carousel-wrapper {
  flex-grow: 1;
  height: 100%;
  min-height: 100%;
}

.top_title {

}
.weather_icon {
  padding-top: 2.6rem;
  padding-right: 0;
  margin: 0;
}
/* navbar */

.connectedBox {

  font-family: 'Manrope';
  font-style: normal;
  font-weight: 400;
  font-size: 1.6em;
  line-height: 22px;

  color: #000000;

}

/* notifications  */



.notification {
  display: "flex";
  flex-direction: "row";
  gap: 1.5;
  background-color: white;
  animation: fadeBackground 6s;
  animation-fill-mode: forwards;
  align-items: "center";
  font-size: 12pt;
}

.notification__actions {
  margin-left: auto;
}

@keyframes fadeBackground {
  from { background-color: #FCE97F; }
  to { background-color: white; }
}

.alert {
  font-weight: bold;

}
/* main */

.dateDisplay {
  font-family: 'Manrope';
font-style: normal;
font-weight: 500;
font-size: 2rem;
line-height: 2.6rem;
padding: 1rem 0 1rem 0;

color: #111111;
}

.sectionTitle {
  font-family: 'Manrope';
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 28px;
}

.dashboardCard {
  background-color: white;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.04);
  border-radius: 12px;
  padding: 1rem 2rem;


}

.dashboardHeaderWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0 1rem 0;
}

.dashboardHeader {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 28px;



  /* Core/Black */
  color: #000000;
  display: flex;
  align-items: center;

}

.dashboardHeaderImage {
  height: 22px;
}
.statsImage {
  width: 600px;
  text-align: center;
  /* height: 100%; */
}

.travelDatesImage {
  width: 140px;
  margin-left: -3px;
}


.guestsImage {
  width: 140px;
  margin-left: -11px
}

.hostImage {
  width: 140px;
  margin-left: 0px;
}

.carousel {
  min-height: 420px;
}